import React, { useState, useRef, useEffect } from "react";
import { Spinner } from "components/ui";
import { AppointmentsContext } from "../use-patientProfileAppointmentsContext";
import DatePicker from "../../../../ui/date-picker";
import { fetchCoachAvailability } from "./fetch-timeslots";
import moment from "moment";
import { useDispatch } from "react-redux";
import "./style.scss";
import PropTypes from "prop-types";

const ScheduleAppointmentModal = ({ onClose, patientId, userId }) => {
  const inputRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingTimeslots, setIsFetchingTimeslots] = useState(false);

  const [hasSearched, setHasSearched] = useState(false);
  const [isTopicSelected, setIsTopicSelected] = useState(false);
  const [filteredCoaches, setFilteredCoaches] = useState([]);

  const [availableSlotsResults, setAvailableTimeSlotsResult] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment().toDate());

  const dispatch = useDispatch();

  const [schedulerOptions, setSchedulerOptions] = React.useState({
    title: "",
    description: "",
    startDate: moment().add(1, "days").format("MM/DD/YYYY"),
    startTime: "20:00",
    endDate: moment().add(1, "days").format("MM/DD/YYYY"),
    endTime: "20:15",
    chat_type: "",
    max_number_of_invitees: 0,
    appointment_data: {
      appointment_patient_ids: [{ patient_name: "", user_id: patientId }],
      appointment_topic_id: ""
    }
  });

  const {
    userAppointmentConfig,
    gettingUserAppointmentConfig,
    coachesByPatient,
    gettingCoachesByPatient,
    selectedCoach,
    topicCategories,
    gettingTopicCategories,
    setSelectedCoach,
    createAppointment
  } = React.useContext(AppointmentsContext);

  const handleSelectChange = event => {
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {
      case "coach":
        setSelectedCoach(value);
        setSchedulerOptions(schedulerOptions => ({
          ...schedulerOptions,
          appointment_data: {
            appointment_patient_ids:
              schedulerOptions.appointment_data.appointment_patient_ids,
            appointment_topic_id:
              schedulerOptions.appointment_data.appointment_topic_id
          }
        }));
        setAvailableTimeSlotsResult(null);
        setSelectedTimeSlot({});
        setHasSearched(false);
        break;
      case "appointment_topic": {
        setSelectedCoach("");
        setSchedulerOptions(schedulerOptions => ({
          ...schedulerOptions,
          appointment_data: {
            appointment_patient_ids:
              schedulerOptions.appointment_data.appointment_patient_ids,
            appointment_topic_id: value
          }
        }));

        const filtered = coachesByPatient.filter(coach =>
          coach.TopicCategories.some(category => category.topic_id === value)
        );
        setFilteredCoaches(filtered);
        setAvailableTimeSlotsResult(null);
        setSelectedTimeSlot({});
        setIsTopicSelected(!!value);
        setHasSearched(false);
        break;
      }
      case "chat_type":
        setSchedulerOptions({
          ...schedulerOptions,
          chat_type: value,
          max_number_of_invitees: value == "Video_Chat" ? 10 : 1
        });
        if (value == "") {
          setAvailableTimeSlotsResult(null);
          setSelectedTimeSlot({});
          setHasSearched(false);
        }
        break;
      case "description":
        setSchedulerOptions({
          ...schedulerOptions,
          description: value
        });
        break;
      default:
        break;
    }
  };

  const handleSelectDate = date => {
    setSelectedDate(date);
    setHasSearched(false);
  };

  useEffect(() => {
    if (
      !hasSearched &&
      schedulerOptions.appointment_data.appointment_topic_id &&
      schedulerOptions.chat_type
    )
      fetchTimeslots();
  }, [
    hasSearched,
    schedulerOptions.appointment_data.appointment_topic_id,
    schedulerOptions.chat_type
  ]);

  const cancelButton = () => {
    setSelectedCoach("");
    setSchedulerOptions(schedulerOptions => ({
      ...schedulerOptions,
      appointment_data: {
        appointment_patient_ids:
          schedulerOptions.appointment_data.appointment_patient_ids,
        appointment_topic_id: ""
      },
      description: "",
      chat_type: ""
    }));
    setAvailableTimeSlotsResult(null);
    setSelectedTimeSlot({});
    onClose();
  };

  const handleSave = async (confirmed = false) => {
    try {
      setIsSaving(true);
      let successfulCreation = false;
      const { coachId, start_time, end_time } = selectedTimeSlot;
      const startDate = createCombinedDateTime(selectedDate, start_time);
      const endDate = createCombinedDateTime(selectedDate, end_time);
      if (userAppointmentConfig.can_schedule_appointments)
        successfulCreation = await createAppointment(
          coachId,
          startDate,
          endDate,
          { ...schedulerOptions, confirmed: confirmed }
        );
      else
        successfulCreation = await createAppointment(
          null,
          startDate,
          endDate,
          schedulerOptions
        );
      if (successfulCreation) {
        onClose();
      }
    } finally {
      setIsSaving(false);
    }
  };

  const style = {
    datePicker: {
      marginLeft: 2,
      display: "block",
      float: "left",
      position: "static"
    },
    containerstyle: {
      position: "relative",
      top: "100%",
      left: 0,
      zIndex: 10,
      boxShadow: "none",
      width: "268px"
    }
  };

  const fetchTimeslots = async () => {
    const appointmentTopicId =
      schedulerOptions.appointment_data.appointment_topic_id;

    let coachId = selectedCoach;
    if (!userAppointmentConfig.can_schedule_appointments) {
      coachId = userId;
    }

    setIsFetchingTimeslots(true);
    try {
      const result = await dispatch(
        fetchCoachAvailability({
          selectedDate,
          appointment_topic_id: appointmentTopicId,
          coachId,
          patientId
        })
      );

      setAvailableTimeSlotsResult(result.slots);
    } catch (error) {
      throw Error("Error fetching timeslots:", error);
    } finally {
      setHasSearched(true);
      setIsFetchingTimeslots(false);
    }
  };

  const createCombinedDateTime = (date, time) => {
    return moment(
      `${moment(date).format("MM/DD/YYYY")} ${time}`,
      "MM/DD/YYYY hh:mm a"
    ).toDate();
  };

  return (
    <div>
      <div className="create-time-slot-modal-container" ref={inputRef}>
        <h4> Create Schedule</h4>

        {gettingTopicCategories ? (
          <Spinner />
        ) : (
          <div className="appointment-topic-container">
            <div style={{ flex: "1" }}>
              <select
                name="appointment_topic"
                id="appointment_topic"
                value={schedulerOptions.appointment_data.appointment_topic_id}
                style={{ width: "45%" }}
                onChange={e => handleSelectChange(e)}
                disabled={isFetchingTimeslots}
              >
                <option value="">Choose a Topic</option>

                {userAppointmentConfig.can_schedule_appointments
                  ? topicCategories.map(({ topic_id, topic_name }) => (
                      <option value={topic_id} key={topic_id}>
                        {topic_name}
                      </option>
                    ))
                  : userAppointmentConfig.appointment_topics?.map(
                      ({ text, value }) => (
                        <option value={value} key={value}>
                          {text}
                        </option>
                      )
                    )}
              </select>
            </div>
          </div>
        )}

        {gettingUserAppointmentConfig ? (
          <Spinner />
        ) : (
          <div>
            {userAppointmentConfig.can_schedule_appointments && (
              <div>
                {gettingCoachesByPatient ? (
                  <Spinner />
                ) : (
                  <div style={{ display: "flex" }}>
                    <select
                      name="coach"
                      id="coach"
                      value={selectedCoach}
                      style={{ width: "45%" }}
                      onChange={e => handleSelectChange(e)}
                      disabled={!isTopicSelected || isFetchingTimeslots}
                    >
                      <option value="">Choose a Coach</option>
                      {filteredCoaches?.map(coach => (
                        <option key={coach.CoachId} value={coach.CoachId}>
                          {coach.CoachName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            )}

            <div style={{ display: "flex" }}>
              <div className="chat-type-and-invites-container">
                <div style={{ flex: "1" }}>
                  <select
                    name="chat_type"
                    id="chat_type"
                    value={schedulerOptions.chat_type}
                    style={{ width: "45%", marginLeft: "-5px" }}
                    onChange={e => handleSelectChange(e)}
                    disabled={isFetchingTimeslots}
                  >
                    <option value="">Choose Chat Type</option>
                    <option value="Text_Chat">Text Only Chat</option>
                    <option value="Video_Chat">Video Chat</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2em"
              }}
            >
              <label
                htmlFor="Datepicker-date"
                className="input-label"
                style={{
                  fontSize: "0.7em",
                  color: "gray"
                }}
              >
                Choose a time slot
              </label>
            </div>
            <div
              style={{ display: "flex", marginTop: "2em" }}
              className="calendar-and-timeslots-container"
            >
              <div style={{ flex: 0.75 }}>
                <div
                  style={{
                    position: "relative",
                    marginTop: "1em",
                    marginBottom: "27em"
                  }}
                  className={
                    isSaving || isFetchingTimeslots ? "disabled-calendar" : ""
                  }
                >
                  <DatePicker
                    disabledDays={{ before: moment().toDate() }}
                    showByDefault={true}
                    css={style.datePicker}
                    containerCss={style.containerstyle}
                    showOutsideDays
                    onChange={date => handleSelectDate(date)}
                    clear={false}
                    managerView={false}
                    hideIcon={true}
                    keepOpen={true}
                    selectedDay={selectedDate}
                  />
                </div>
              </div>
              <form
                style={{
                  flex: 1.25,
                  width: "100%",
                  height: "300px",
                  overflowY: "auto"
                }}
              >
                {isFetchingTimeslots ? (
                  <div className="spinner-container">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {availableSlotsResults != null &&
                    availableSlotsResults.length > 0 ? (
                      <fieldset
                        style={{
                          padding: "4px",
                          border: "1px solid #ddd",
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(165px, 1fr))"
                        }}
                      >
                        {availableSlotsResults.map(coach =>
                          coach.AvailabilitySlotsTimes.map(slot => {
                            const start_time = moment(
                              slot.Start,
                              "HH:mm"
                            ).format("hh:mm a");
                            const end_time = moment(slot.End, "HH:mm").format(
                              "hh:mm a"
                            );
                            return (
                              <label
                                key={`${coach.CoachId}_${start_time}-${end_time}`}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "0.2em",
                                  fontSize: "0.9em"
                                }}
                              >
                                <input
                                  type="radio"
                                  name="time-slot"
                                  value={`${coach.CoachId}_${start_time}/${end_time}`}
                                  checked={
                                    selectedTimeSlot.coachId ===
                                      coach.CoachId &&
                                    selectedTimeSlot.start_time ===
                                      start_time &&
                                    selectedTimeSlot.end_time === end_time
                                  }
                                  onChange={() =>
                                    setSelectedTimeSlot({
                                      coachId: coach.CoachId,
                                      start_time: start_time,
                                      end_time: end_time
                                    })
                                  }
                                  style={{
                                    marginTop: "12px",
                                    marginRight: "8px",
                                    padding: "3px"
                                  }}
                                />
                                {`${
                                  coach.CoachName
                                } | ${start_time.toUpperCase()}`}
                              </label>
                            );
                          })
                        )}
                      </fieldset>
                    ) : (
                      hasSearched && (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            color: "red",
                            paddingTop: "5em"
                          }}
                        >
                          No timeslots found
                        </div>
                      )
                    )}
                  </>
                )}
              </form>
            </div>
            <div style={{ display: "flex", marginTop: "2em" }}>
              <div style={{ flex: 1, position: "relative" }}>
                <textarea
                  name="description"
                  id="description"
                  style={{
                    width: "100%",
                    height: "80px",
                    padding: "10px",
                    boxSizing: "border-box",
                    resize: "none"
                  }}
                  className="text-input-styles"
                  placeholder="Enter a description ..."
                  value={schedulerOptions.description}
                  onChange={e => handleSelectChange(e)}
                />
              </div>
            </div>

            <div
              className="action-buttons-container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                className="warning create-timeslot-button"
                onClick={() => {
                  cancelButton();
                }}
              >
                Cancel
              </button>

              {userAppointmentConfig.can_schedule_appointments && (
                <button
                  className="success create-timeslot-button"
                  onClick={() => handleSave(true)}
                  disabled={
                    isSaving ||
                    !schedulerOptions.appointment_data.appointment_topic_id ||
                    !schedulerOptions.chat_type ||
                    !selectedTimeSlot ||
                    Object.keys(selectedTimeSlot).length === 0
                  }
                >
                  Save & Confirm
                </button>
              )}
              <button
                className="create-timeslot-button"
                onClick={() => handleSave()}
                disabled={
                  isSaving ||
                  !schedulerOptions.appointment_data.appointment_topic_id ||
                  !schedulerOptions.chat_type ||
                  !selectedTimeSlot ||
                  Object.keys(selectedTimeSlot).length === 0
                }
              >
                Save
              </button>
            </div>
          </div>
        )}

        {isSaving && (
          <div
            className="modal-spinner-container"
            style={{ height: "100%", width: "100%" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

ScheduleAppointmentModal.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.string,
  userId: PropTypes.string
};

export default ScheduleAppointmentModal;
