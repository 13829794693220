import React, { useState, useEffect } from "react";
import { get, put } from "utils/api";
import { PageHeader, TabList } from "components/ui";
import { Switch, Route } from "react-router-dom";
import AvailabilityScheduler from "./availability-scheduler";
import UpcomingAppointments from "./upcoming/index.js";
import ChatHistory from "./history";
import ManagerView from "./manager-view";
import { selectors } from "reducers/user";
import { useSelector, useDispatch } from "react-redux";
import FaRefresh from "react-icons/lib/fa/refresh";
import { getCurrentTimeZone } from "utils/dates";
import { useFetch } from "hooks";
import { path } from "ramda";
import { errorMessage, message } from "actions/message";
import { getApiErrorMessage } from "utils/misc";

const ChatsContainer = () => {
  const [currentCoachTimeZoneInfo, setCurrentCoachTimeZoneInfo] = useState("");
  const [timeZone, setTimeZone] = useState(null);
  const dispatch = useDispatch();
  const { can_be_scheduled } = useSelector(selectors.getUser);
  const userRole = useSelector(selectors.getUserRole);
  const isAdmin =
    userRole == "CompanyAdministrator" ||
    userRole == "Administrator" ||
    userRole == "ServiceAdmin";
  const [canSchedule, setCanSchedule] = React.useState(false);

  useEffect(() => {
    const fetchUserAppointmentConfig = async () => {
      try {
        const response = await get("/users/appointment_config");
        setCanSchedule(response?.can_schedule_appointments);
      } catch (error) {
        dispatch(
          errorMessage(
            error.response?.body?.message ??
              error.message ??
              "Failed to get appointment config"
          )
        );
      }
    };

    fetchUserAppointmentConfig();
  }, []);

  useEffect(() => {
    var userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
    if (!userLoggedIn.timezoneExists) updateTimeZone();
    else updateTimeZoneDescription(userLoggedIn.timezone);
  }, []);

  const { fetchData: fetchUpdateTimeZone } = useFetch({
    apiFn: data =>
      put(`users/update_timezone`, JSON.stringify(data), {
        "Content-Type": "application/json"
      }),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onSuccess: () => {
      dispatch(message("Time zone updated successfully"));
    },
    onError: error => {
      dispatch(
        errorMessage(`Failed to update time zone: ${getApiErrorMessage(error)}`)
      );
    }
  });

  const updateTimeZone = async () => {
    var currentTimeZone = getCurrentTimeZone();
    var timeZoneSaved = await fetchUpdateTimeZone(currentTimeZone);
    if (timeZoneSaved) updateTimeZoneInfo(timeZoneSaved);
    updateTimeZoneDescription(timeZoneSaved);
    setTimeZone(timeZoneSaved);
  };

  const updateTimeZoneInfo = currentTimeZone => {
    var userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
    if (userLoggedIn) {
      userLoggedIn.timezone = currentTimeZone;
      window.localStorage.setItem("evergreen", JSON.stringify(userLoggedIn));
    }
  };

  const updateTimeZoneDescription = currentTimeZone => {
    const optionsShort = {
      timeZoneName: "short",
      timeZone: currentTimeZone
    };

    const optionsLong = {
      timeZoneName: "long",
      timeZone: currentTimeZone
    };

    const optionsLongOffset = {
      timeZoneName: "longOffset",
      timeZone: currentTimeZone
    };

    const optionsShortOffset = {
      timeZoneName: "shortOffset",
      timeZone: currentTimeZone
    };

    var longTimeFormat = new Intl.DateTimeFormat("en-US", optionsLong);
    var shortTimeFormat = new Intl.DateTimeFormat("en-US", optionsShort);
    var longOffsetTimeFormat = new Intl.DateTimeFormat(
      "en-US",
      optionsLongOffset
    );
    var shortOffsetTimeFormat = new Intl.DateTimeFormat(
      "en-US",
      optionsShortOffset
    );

    var timeZoneShortName = shortTimeFormat
      .formatToParts()
      .find(x => x.type === "timeZoneName").value;
    var timeZoneLongName = longTimeFormat
      .formatToParts()
      .find(x => x.type === "timeZoneName").value;
    var timeZoneLongOffset = longOffsetTimeFormat
      .formatToParts()
      .find(x => x.type === "timeZoneName").value;
    var timeZoneShortOffset = shortOffsetTimeFormat
      .formatToParts()
      .find(x => x.type === "timeZoneName").value;

    var titleText =
      timeZoneShortName == timeZoneShortOffset
        ? timeZoneLongName + " (" + timeZoneLongOffset + ")"
        : timeZoneShortName +
          " - " +
          timeZoneLongName +
          " (" +
          timeZoneLongOffset +
          ")";

    setCurrentCoachTimeZoneInfo(titleText);
  };

  const resetTimeZoneValue = () => {
    setTimeZone(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        overflow: "hidden"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <PageHeader title="Video/Text Chats" />
        <div
          style={{
            textAlign: "right",
            paddingBottom: "8px",
            marginRight: "20px",
            alignItems: "right",
            fontFamily: "Poppins-Regular, PT-Sans, sans-serif"
          }}
        >
          Your TimeZone:{" "}
          <span style={{ color: "SteelBlue", paddingRight: "5px" }}>
            {currentCoachTimeZoneInfo}
          </span>
          <FaRefresh
            onClick={updateTimeZone}
            style={{
              fill: "SteelBlue",
              border: "SteelBlue",
              cursor: "pointer"
            }}
          />
        </div>
      </div>
      <div
        className="chat-tabs"
        style={{
          display: "flex",
          padding: "5px 0px",
          alignItems: "center"
        }}
      >
        <TabList
          routes={[
            {
              path: `/chats/upcoming`,
              label: "Upcoming"
            },
            can_be_scheduled
              ? {
                  path: `/chats/time-slots`,
                  label: "My Schedule"
                }
              : false,
            {
              path: `/chats/history`,
              label: "History"
            },
            isAdmin && canSchedule
              ? {
                  path: `/chats/manager-view`,
                  label: "Manager View"
                }
              : false
          ].filter(Boolean)}
        />
      </div>

      <Switch>
        <Route path="/chats/time-slots">
          <AvailabilityScheduler
            timeZone={timeZone}
            resetTimeZoneValue={() => resetTimeZoneValue()}
          />
        </Route>
        <Route path="/chats/upcoming">
          <UpcomingAppointments
            timeZone={timeZone}
            resetTimeZoneValue={() => resetTimeZoneValue()}
          />
        </Route>
        <Route path="/chats/history">
          <ChatHistory
            timeZone={timeZone}
            resetTimeZoneValue={() => resetTimeZoneValue()}
          />
        </Route>
        {isAdmin && canSchedule && (
          <Route path="/chats/manager-view">
            <ManagerView
              timeZone={timeZone}
              resetTimeZoneValue={() => resetTimeZoneValue()}
            />
          </Route>
        )}
      </Switch>
    </div>
  );
};

export default ChatsContainer;
