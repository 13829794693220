import React from "react";
import { get, put, post, del } from "utils/api";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { message, errorMessage } from "actions/message";
import {
  getCoachesAPI,
  getUserAppointmentConfigAPI,
  getCoachAppointmentConfigAPI,
  createAppointmentAPI,
  getTopicCategoriesAPI
} from "../scheduler-actions/scheduler-api-actions";

const useAvailabilityScheduler = () => {
  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

  const dispatch = useDispatch();
  const [userTimeSlots, setUserTimeSlots] = React.useState([]);
  const [userAvailability, setUserAvailability] = React.useState([]);
  const [pendingTimeSlot, setPendingTimeSlot] = React.useState(null);

  const today = moment().tz(userLoggedIn.timezone);
  const [week, selectWeek] = React.useState(today.week());
  const [date, selectDate] = React.useState(today);
  const [day, selectDay] = React.useState(today.day());
  const [month, selectMonth] = React.useState(
    parseInt(today.toDate().getMonth(), 10)
  );
  const [year, selectYear] = React.useState(today.toDate().getFullYear());

  const [selected, setSelected] = React.useState({
    row1: null,
    col: null
  });
  const [editSlot, setEditSlot] = React.useState();
  const [gettingTimeSlots, setGettingTimeSlots] = React.useState(false);
  const [gettingUserAvailability, setGettingUserAvailability] =
    React.useState(false);
  const [userAppointmentConfig, setUserAppointmentConfig] = React.useState([]);
  const [gettingUserAppointmentConfig, setGettingUserAppointmentConfig] =
    React.useState(false);
  const [creatingOrEditingTimeSlots, setCreatingOrEditingTimeSlots] =
    React.useState(false);

  const [managerAppointments, setManagerAppointments] = React.useState([]);
  const [managerViewCoaches, setManagerViewCoaches] = React.useState([]);
  const [managerViewCoachesAvailability, setManagerViewCoachesAvailability] =
    React.useState([]);
  const [isManagerView, setIsManagerView] = React.useState(false);
  const [fetchingManagerView, setFetchingManagerView] = React.useState(false);
  const [
    managerViewCoachAppointmentConfig,
    setManagerViewCoachAppointmentConfig
  ] = React.useState(null);
  const [managerViewView, setManagerViewView] = React.useState("day");

  const [coachViewView, setCoachViewView] = React.useState("day");
  const [coachViewCoachID, setCoachViewCoachID] = React.useState("");
  const [coachViewAppointments, setCoachViewAppointments] = React.useState([]);
  const [coachViewAvailability, setCoachViewAvailability] = React.useState([]);
  const [gettingCoachViewAppointments, setGettingCoachViewAppointments] =
    React.useState(false);

  const [coachViewAppointmentsMonth, setCoachViewAppointmentsMonth] =
    React.useState([]);
  const [coachViewAvailabilityMonth, setCoachViewAvailabilityMonth] =
    React.useState([]);
  const [
    gettingCoachViewAppointmentsForMonth,
    setGettingCoachViewAppointmentsForMonth
  ] = React.useState(false);

  const [patients, setPatients] = React.useState([]);
  const [gettingPatients, setGettingPatients] = React.useState(false);
  const [coaches, setCoaches] = React.useState([]);
  const [gettingCoaches, setGettingCoaches] = React.useState(false);
  const [topicCategories, setTopicCategories] = React.useState([]);
  const [gettingTopicCategories, setGettingTopicCategories] =
    React.useState(false);

  React.useEffect(() => {
    getTopicCategoriesAPI(
      dispatch,
      setGettingTopicCategories,
      setTopicCategories
    );
  }, []);

  React.useEffect(() => {
    getUserTimeSlots(week);
    getUserAvailability();

    if (!isManagerView) getUserAppointmentConfig();
  }, [week]);

  React.useEffect(() => {
    if (!gettingTimeSlots && isManagerView) getManagerViewAppointments();
  }, [date]);

  React.useEffect(() => {
    if (coachViewCoachID) {
      setCoachViewAppointments([]);
      getCoachViewAppointments(coachViewCoachID);
    }
  }, [coachViewView, coachViewCoachID, date, week]);

  React.useEffect(() => {
    if (coachViewView === "month" && coachViewCoachID) {
      setCoachViewAppointmentsMonth([]);
      setCoachViewAvailabilityMonth([]);
      getCoachViewAppointmentsMonth(coachViewCoachID);
      getManagerViewCoachAppointmentConfig(coachViewCoachID);
    }
  }, [coachViewCoachID, coachViewView, month, year]);

  React.useEffect(() => {
    if (managerViewCoachAppointmentConfig)
      setCoachViewAvailabilityMonth(
        managerViewCoachAppointmentConfig.days.filter(d => d.active)
      );
  }, [managerViewCoachAppointmentConfig]);

  React.useEffect(() => {
    if(isManagerView)
      getCoachesAPI(dispatch, setGettingCoaches, setCoaches);
  }, [isManagerView])

  const getUserTimeSlots = async week => {
    setGettingTimeSlots(true);
    const startDate = moment().week(week).startOf("week").format("L");
    const endDate = moment().week(week).endOf("week").format("L");
    const slots = await put("/appointments/coach-schedule", {
      from_date: startDate,
      to_date: endDate
    });

    const slotsWithFormatedTimes = slots?.map(s => ({
      ...s,
      start_time: moment(s.StartDate).format("L LT"),
      end_time: moment(s.EndDate).format("L LT")
    }));
    setUserTimeSlots(slotsWithFormatedTimes ?? []);
    setGettingTimeSlots(false);
  };

  const getUserAvailability = async () => {
    setGettingUserAvailability(true);
    let res = await get("/users/availability");
    res = res.filter(d => d.active);
    setUserAvailability(res);
    setGettingUserAvailability(false);
  };


  const getManagerViewCoachAppointmentConfig = async coachId => {
    getCoachAppointmentConfigAPI(
      dispatch,
      setGettingUserAppointmentConfig,
      setManagerViewCoachAppointmentConfig,
      coachId
    );
  };

  const getUserAppointmentConfig = async () => {
    if (!isManagerView) {
      getUserAppointmentConfigAPI(
        dispatch,
        setGettingUserAppointmentConfig,
        setUserAppointmentConfig
      );
    }
  };

  const createTimeSlot = async (
    start_time,
    end_time,
    {
      title,
      description,
      max_number_of_invitees,
      service_ids,
      product_ids,
      chat_type,
      recurring,
      recurrenceEndDate,
      weekdays,
      appointment_data
    }
  ) => {
    try {
      const patients = appointment_data.appointment_patient_ids.map(
        ({ user_id }) => {
          return user_id;
        }
      );
      const clean_appointment_data = {
        appointment_topic_id: appointment_data.appointment_topic_id,
        appointment_patient_ids: patients
      };
      const { success, message: responseMsg } = await post("/appointments", {
        start_time: moment(start_time).format("L LT"),
        end_time: moment(end_time).format("L LT"),
        title,
        description,
        max_number_of_invitees,
        service_ids,
        product_ids,
        chat_type,
        recurring,
        recurrenceEndDate,
        weekdays,
        appointment_data: clean_appointment_data
      });
      if (success) dispatch(message(responseMsg));
      else dispatch(errorMessage(responseMsg));
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to create time slot"
        )
      );
      return false;
    }
  };

  const createAppointment = async (coachId, start_time, end_time, options) => {
    return await createAppointmentAPI(
      dispatch,
      coachId,
      start_time,
      end_time,
      options
    );
  };

  const managerViewEditAppointment = async (
    appointmentId,
    start_time,
    end_time,
    { description, appointment_data }
  ) => {
    try {
      const patients = appointment_data.appointment_patient_ids.map(
        ({ user_id }) => {
          return user_id;
        }
      );
      const clean_appointment_data = {
        appointment_topic_id: appointment_data.appointment_topic_id,
        appointment_patient_ids: patients
      };
      const { success, message: responseMsg } = await put(
        "/appointments/manager-view/edit",
        {
          StartDate: moment(start_time).format("L LT"),
          EndDate: moment(end_time).format("L LT"),
          Description: description,
          AppoitmentData: clean_appointment_data,
          AppointmentId: appointmentId
        }
      );

      if (success) dispatch(message(responseMsg));
      else dispatch(errorMessage(responseMsg));
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to create appointment"
        )
      );
      return false;
    }
  };

  const managerViewCancelAppointment = async appointmentId => {
    try {
      const { success, message: responseMsg } = await put(
        `/appointments/manager-view/${appointmentId}/cancel`
      );

      if (success) dispatch(message(responseMsg));
      else dispatch(errorMessage(responseMsg));
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to cancel appointment"
        )
      );
      return false;
    }
  };

  const deleteTimeSlotById = async timeSlotId => {
    const result = await del(`/dme_portal/slot/${timeSlotId}`);

    if (result && result.success) dispatch(message(result.message));
    else if (!result.success) dispatch(errorMessage(result.message));

    return result.success;
  };

  const editAppointmentById = async (
    appointmentId,
    { appointment_data },
    coachId
  ) => {
    const patients = appointment_data.appointment_patient_ids.map(
      ({ user_id }) => {
        return user_id;
      }
    );

    try {
      const result = await put(
        `/appointments/${appointmentId}/${coachId}`,
        patients
      );
      if (result && result.message)
        if (result.success) dispatch(message(result.message));
        else dispatch(errorMessage(result.message));
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ??
            err.message ??
            "Failed to Update Appointment"
        )
      );
    }
  };

  const getManagerViewAppointments = async () => {
    setIsManagerView(true);
    setFetchingManagerView(true);
    const startDate = date.format("MM/DD/YYYY");
    const endDate = date.format("MM/DD/YYYY");
    const res = await put("/appointments/manager-view", {
      ToDate: endDate,
      FromDate: startDate,
      ByDay: true,
      ByCoach: null
    });

    const coaches = res.map(appointment => {
      return {
        name: appointment.CoachInfo.CoachName,
        id: appointment.CoachInfo.CoachId,
        active: appointment.CoachInfo.Active
      };
    });
    setManagerViewCoaches(coaches);

    const appointmentsArray = [];
    res.map(appointment =>
      appointment.Appointments.map(patientAppointment =>
        appointmentsArray.push({
          ...patientAppointment,
          start_time: moment(patientAppointment.StartDate).format("L LT"),
          end_time: moment(patientAppointment.EndDate).format("L LT"),
          slot_id: patientAppointment.AppointmentId
        })
      )
    );

    setManagerAppointments(appointmentsArray);

    const coachesAvailability = [];
    res.map(appointment =>
      appointment.AppointmentDaysConfig.map(d =>
        coachesAvailability.push({
          ...d,
          coachId: appointment.CoachInfo.CoachId
        })
      )
    );
    setManagerViewCoachesAvailability(coachesAvailability);

    setFetchingManagerView(false);
  };

  const isBetweenWorkingTime = (value, col) => {
    const dayFromGridPosition = date.day();
    const coachAvailability = getManagerViewCoachAvailability(
      col,
      dayFromGridPosition
    );

    if (coachAvailability == undefined) return;

    let { start, end, lunch_start, lunch_end } = coachAvailability;

    const timeFromGridPosition = value.format("HH:mm");
    start = moment(start, "hh:mm a").format("HH:mm");
    end = moment(end, "hh:mm a").format("HH:mm");
    lunch_start = moment(lunch_start, "hh:mm a").format("HH:mm");
    lunch_end = moment(lunch_end, "hh:mm a").format("HH:mm");

    const isLunchTime =
      lunch_start <= timeFromGridPosition && timeFromGridPosition < lunch_end;
    const isWorkingTime =
      start <= timeFromGridPosition && timeFromGridPosition <= end;

    if (isLunchTime) {
      return "lunch";
    } else if (isWorkingTime) {
      return "work";
    }
    return;
  };

  const getManagerViewCoachAvailability = (coachId, day) => {
    return managerViewCoachesAvailability.find(
      x => x.day == day && x.coachId == coachId && x.active
    );
  };

  const getCoachViewDatesHelper = () => {
    let startDate, endDate;

    if (coachViewView == "day") {
      startDate = date.format("MM/DD/YYYY");
      endDate = date.format("MM/DD/YYYY");
    } else if (coachViewView == "week") {
      startDate = moment().week(week).startOf("week");
      endDate = moment().week(week).endOf("week");
    } else if (coachViewView == "month") {
      startDate = moment(new Date(year, month, 1)).format("MM/DD/YYYY");
      endDate = moment(new Date(year, month + 1, 0)).format("MM/DD/YYYY");
    }

    return { startDate, endDate };
  };
  const getCoachViewAppointments = async coachId => {
    setGettingCoachViewAppointments(true);

    const { startDate, endDate } = getCoachViewDatesHelper();

    const res = await put("/appointments/manager-view", {
      ToDate: endDate,
      FromDate: startDate,
      ByDay: startDate == endDate ? true : false,
      ByCoach: coachId
    });
    const formattedAppointments = res[0]?.Appointments?.map(a => ({
      ...a,
      start_time: moment(a.StartDate).format("L LT"),
      end_time: moment(a.EndDate).format("L LT"),
      slot_id: a.AppointmentId
    }));

    setCoachViewAppointments(formattedAppointments);

    if (res.length != 0) {
      setCoachViewAvailability(
        res[0]?.AppointmentDaysConfig.filter(d => d.active)
      );
    }

    setGettingCoachViewAppointments(false);
  };

  const getCoachViewAppointmentsMonth = async coachId => {
    setGettingCoachViewAppointmentsForMonth(true);
    const { startDate, endDate } = getCoachViewDatesHelper();

    const res = await put("/appointments/manager-view", {
      ToDate: endDate,
      FromDate: startDate,
      ByDay: false,
      ByCoach: coachId
    });

    const formattedAppointments = res[0]?.Appointments?.map(a => ({
      ...a,
      start_time: moment(a.StartDate).format("L LT"),
      end_time: moment(a.EndDate).format("L LT"),
      slot_id: a.AppointmentId
    }));

    setCoachViewAppointmentsMonth(formattedAppointments);

    setGettingCoachViewAppointmentsForMonth(false);
  };

  const getCoachViewAvailability = day =>
    coachViewAvailability?.find(x => x.day == day);

  return {
    userTimeSlots,
    userAvailability,
    userAppointmentConfig,
    getUserTimeSlots,
    getUserAvailability,
    gettingUserAvailability,
    getUserAppointmentConfig,
    createTimeSlot,
    createAppointment,
    editAppointmentById,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    year,
    month,
    week,
    day,
    selectYear,
    selectMonth,
    selectWeek,
    selectDay,
    selected,
    setSelected,
    editSlot,
    setEditSlot,
    gettingTimeSlots,
    gettingUserAppointmentConfig,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots,
    date,
    selectDate,
    getManagerViewAppointments,
    managerAppointments,
    managerViewCoaches,
    getManagerViewCoachAppointmentConfig,
    isManagerView,
    setIsManagerView,
    fetchingManagerView,
    getManagerViewCoachAvailability,
    isBetweenWorkingTime,
    managerViewView,
    setManagerViewView,

    coachViewView,
    setCoachViewView,
    coachViewAppointmentsMonth,
    coachViewAvailabilityMonth,
    coachViewAppointments,
    coachViewAvailability,
    coachViewCoachID,
    setCoachViewCoachID,
    getCoachViewAvailability,
    gettingCoachViewAppointments,
    gettingCoachViewAppointmentsForMonth,
    setGettingCoachViewAppointments,
    getCoachViewAppointments,
    managerViewEditAppointment,
    managerViewCancelAppointment,

    coaches,
    gettingCoaches,
    topicCategories,
    gettingTopicCategories,
    patients,
    gettingPatients,
    getCoachViewAppointmentsMonth
  };
};

export default useAvailabilityScheduler;

export const TimeSlotContext = React.createContext();
