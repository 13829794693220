import React, { useEffect, useState, useRef } from "react";
import { TimeSlotContext } from "./use-availabilityScheduler";
import {
  PureModal,
  DatePickerInput,
  Spinner,
  InlineWaiting,
  ConfirmationDialog
} from "components/ui";
import moment from "moment";
import { PropTypes } from "prop-types";
import { useFetch } from "hooks";
import { put, get } from "utils/api";
import PatientSearchResults from "components/patient-search-form-for-modal/patient-search-results";
import {
  MdAddCircle,
  MdPeople,
  MdSchedule,
  MdDescription,
  MdClose,
  MdAssignment,
  MdSearch
} from "react-icons/lib/md";
import { useDispatch } from "react-redux";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { errorMessage } from "actions/message";

import MoveAppointment from "../available-coaches/Index";

export const CreateEditViewTimeSlotModal = ({
  timeZone,
  data,
  managerView,
  isViewing
}) => {
  const {
    setPendingTimeSlot,
    getUserTimeSlots,
    pendingTimeSlot,
    createAppointment,
    userAppointmentConfig,
    week,
    setCreatingOrEditingTimeSlots,
    creatingOrEditingTimeSlots,
    editAppointmentById,
    setEditSlot,
    gettingUserAppointmentConfig,
    getManagerViewAppointments,
    isManagerView,
    coachViewCoachID,
    getCoachViewAppointments,
    managerViewEditAppointment,
    managerViewCancelAppointment,
    getManagerViewCoachAvailability,
    getCoachViewAvailability,
    coachViewView,
    getCoachViewAppointmentsMonth
  } = React.useContext(TimeSlotContext);

  const canEdit = !data || (data && isManagerView);
  const canAddPatients =
    data &&
    (data.AssignedPatientsCount == 0 ||
      data.AssignedPatientsCount < data.MaxInvitees);
  const isExpired = data && data.Status.toLowerCase() == "expired";
  const isTimeOff = data && data.Status.toLowerCase() == "timeoff";
  const dispatch = useDispatch();

  const [baseCloseModal] = useState(() => { });
  const [schedulerOptions, setSchedulerOptions] = React.useState({
    title: "",
    description: "",
    max_number_of_invitees: 0,
    chat_type: "Undefined",
    appointment_data: {
      appointment_patient_ids: [],
      appointment_topic_id: ""
    }
  });
  const [tablePatients, setTablePatients] = useState([]);

  const coachId =
    pendingTimeSlot && pendingTimeSlot.coachId
      ? pendingTimeSlot.coachId
      : isManagerView && data && data.CoachId
        ? data.CoachId
        : coachViewCoachID;
  const {
    response: managerViewCoachConfig,
    fetchData: fetchManagerViewCoachConfig
  } = useFetch({
    apiFn: () => get(`/users/${coachId}/appointment_config`),
    onError: error =>
      dispatch(errorMessage(getApiErrorMessageCustomPathEnd(error, "message")))
  });

  const {
    response: patients,
    fetchData,
    isFetching
  } = useFetch({
    apiFn: values => put("dme_portal/search_patients", values),
    defaultValue: [],
    transformResponse: ({ patients }) => patients,
    onError: error =>
      dispatch(errorMessage(getApiErrorMessageCustomPathEnd(error, "message")))
  });

  const {
    response: topics_duration,
    fetchData: fetchTopicsDuration,
    isFetching: isFetchingTopicsDuration
  } = useFetch({
    apiFn: () => get(`companies/topics-duration`),
    defaultValue: []
  });

  useEffect(() => {
    if (!data) {
      fetchTopicsDuration();
    }
  }, []);

  useEffect(() => {
    setTablePatients(patients);
  }, [patients]);

  useEffect(() => {
    if (isManagerView && !isExpired) fetchManagerViewCoachConfig();
  }, []);

  const [toSearchPatientsWith, setToSearchPatientsWith] = React.useState({
    name: "",
    email: "",
    date_of_birth: "",
    account_number: "",
    feature: "scheduleChat",
    target_user_id: coachId
  });

  const inputRef = useRef(null);

  const [modalStartTime, setModalStartTime] = React.useState();
  const [modalEndTime, setModalEndTime] = React.useState();

  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [newStartTime, setNewStartTime] = useState("");
  const [newEndTime, setNewEndTime] = useState("");

  const [isValidDateRange, setIsValidDateRange] = useState(true);

  const [validMaximumUsers, setValidMaximumUsers] = useState(true);

  const [aMomentIsSelectedInThePast, setAMomentIsSelectedInThePast] =
    useState(false);

  const time12to24 = time12 => {
    return new Date(time12).toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric"
    });
  };

  const isBeforeNow = day =>
    moment(day, "MM/DD/YYYY HH:mm").isBefore(
      moment().tz(timeZone).format("MM/DD/YYYY HH:mm")
    );

  const currentTimeIsBetween = (startTime, endTime) =>
    moment(moment().tz(timeZone).format("MM/DD/YYYY HH:mm")).isBetween(
      startTime,
      endTime,
      undefined,
      []
    );

  useEffect(() => {
    if (newStartDate && newStartTime) {
      setModalStartTime(`${newStartDate} ${newStartTime}`);
    }
    if (newEndDate && newEndTime) {
      setModalEndTime(`${newEndDate} ${newEndTime}`);
    }
  }, [newStartDate, newEndDate, newStartTime, newEndTime]);

  useEffect(() => {
    if (isBeforeNow(modalStartTime) || isBeforeNow(modalEndTime)) {
      if (!currentTimeIsBetween(modalStartTime, modalEndTime)) {
        setAMomentIsSelectedInThePast(true);
      }
    } else {
      setAMomentIsSelectedInThePast(false);
    }
  }, [modalStartTime, modalEndTime]);

  useEffect(() => {
    if (pendingTimeSlot) {
      setNewStartTime(time12to24(`${pendingTimeSlot.start_time}`));
      setNewEndTime(time12to24(`${pendingTimeSlot.end_time}`));
      setNewStartDate(pendingTimeSlot.start_time.split(" ")[0]);
      setNewEndDate(pendingTimeSlot.end_time.split(" ")[0]);
      setSchedulerOptions({
        ...schedulerOptions,
        title: "",
        description: "",
        max_number_of_invitees: 0,
        chat_type: "Undefined",
        appointment_data: {
          appointment_patient_ids: [],
          appointment_topic_id: ""
        }
      });
    } else if (data) {
      setNewStartTime(time12to24(`${data.start_time}`));
      setNewEndTime(time12to24(`${data.end_time}`));
      setNewStartDate(data.start_time.split(" ")[0]);
      setNewEndDate(data.end_time.split(" ")[0]);
      setSchedulerOptions({
        ...schedulerOptions,
        title: data.Title,
        description: data.Description,
        max_number_of_invitees: data.MaxInvitees,
        chat_type: data.ChatType,
        appointment_data: {
          appointment_patient_ids: isManagerView ? data.AssignedPatients : [],
          appointment_topic_id: data.TopicMGID ?? ""
        }
      });
    }
  }, [pendingTimeSlot]);

  const checkIfAppoinmentIsNonWorkingHours = () => {
    const selectedDay = moment(newStartDate).day();
    const start_time = moment(newStartTime, "hh:mm A").format("HH:mm");
    const end_time = moment(newEndTime, "hh:mm A").format("HH:mm");

    let workHoursThatDay;

    if (coachViewCoachID) {
      workHoursThatDay = getCoachViewAvailability(selectedDay);
    } else if (isManagerView && coachViewCoachID == "") {
      workHoursThatDay = getManagerViewCoachAvailability(coachId, selectedDay);
    } else {
      workHoursThatDay = userAppointmentConfig.days.filter(
        x => x.day == selectedDay
      )?.[0];
    }

    if (!workHoursThatDay) return true;
    let { start, end } = workHoursThatDay;

    start = moment(start, "hh:mm A").format("HH:mm");
    end = moment(end, "hh:mm A").format("HH:mm");

    const isNonOutsideWorkingHours =
      start_time < start ||
      start_time > end ||
      end_time < start ||
      end_time > end;
    return isNonOutsideWorkingHours;
  };
  const openOutsideWorkingHoursConfirmationModal = () => {
    var trigger = document.getElementById(
      "confirmAppointmentOutOfWorkingHoursModal"
    );
    trigger?.click();
  };

  const handleSave = async (
    closeModal,
    confirmationNeeded,
    confirmedNonWorkingHourAppointment,
    confirmed = false
  ) => {
    const rescheduleWarnign =
      data &&
      isManagerView &&
      (moment(modalStartTime).format("L LT") != data.start_time ||
        moment(modalEndTime).format("L LT") != data.end_time);

    const isNonOutsideWorkingHours = checkIfAppoinmentIsNonWorkingHours();
    if (isNonOutsideWorkingHours && !confirmedNonWorkingHourAppointment) {
      openOutsideWorkingHoursConfirmationModal();
      return;
    }

    if (rescheduleWarnign) {
      document.getElementById("rescheduleTimeSlotConfirmationModal").click();
    } else {
      if (confirmationNeeded) {
        closeModal();
      }

      try {
        setCreatingOrEditingTimeSlots(true);
        let successfulCreation = false;
        if (data) {
          //Edit
          if (!isManagerView) {
            successfulCreation = await editAppointmentById(
              data.AppointmentId,
              schedulerOptions,
              data.CoachId
            );
          } else {
            successfulCreation = await managerViewEditAppointment(
              data.AppointmentId,
              modalStartTime,
              modalEndTime,
              schedulerOptions
            );
          }
        } else {
          //Create
          if (isManagerView) {
            if (coachViewCoachID == "" && pendingTimeSlot) {
              successfulCreation = await createAppointment(
                pendingTimeSlot.coachId,
                modalStartTime,
                modalEndTime,
                { ...schedulerOptions, confirmed: confirmed }
              );
            } else {
              successfulCreation = await createAppointment(
                coachViewCoachID,
                modalStartTime,
                modalEndTime,
                { ...schedulerOptions, confirmed: confirmed }
              );
            }
          } else {
            successfulCreation = await createAppointment(
              null,
              modalStartTime,
              modalEndTime,
              { ...schedulerOptions, confirmed: confirmed }
            );
          }
        }

        if (successfulCreation) {
          if (confirmationNeeded) {
            baseCloseModal();
          } else {
            closeModal();
          }
          setPendingTimeSlot(null);
          setEditSlot(null);

          refreshCalendars();
        }
      } finally {
        setCreatingOrEditingTimeSlots(false);
      }
    }
  };

  const refreshCalendars = () => {
    if (coachViewCoachID) {
      if (coachViewView === "month")
        getCoachViewAppointmentsMonth(coachViewCoachID);
      else getCoachViewAppointments(coachViewCoachID);
    } else if (managerView || isManagerView) {
      getManagerViewAppointments();
    } else getUserTimeSlots(week);
  };

  const cancelButton = closeModal => {
    setPendingTimeSlot(null);
    setEditSlot(null);
    closeModal();
  };

  const cancelAppointmentButton = async closeModal => {
    try {
      setCreatingOrEditingTimeSlots(true);
      const successfulCancelation = await managerViewCancelAppointment(
        data.AppointmentId
      );

      if (successfulCancelation) {
        closeModal();
        setPendingTimeSlot(null);
        if (coachViewCoachID) {
          if (coachViewView === "month")
            getCoachViewAppointmentsMonth(coachViewCoachID);
          else getCoachViewAppointments(coachViewCoachID);
        } else if (managerView || isManagerView) {
          getManagerViewAppointments();
        } else getUserTimeSlots(week);
        setEditSlot(null);
      }
    } finally {
      setCreatingOrEditingTimeSlots(false);
    }
  };

  const rescheduleAppointmentButton = async closeModal => {
    try {
      setCreatingOrEditingTimeSlots(true);
      const successfulCancelation = await managerViewEditAppointment(
        data.AppointmentId,
        modalStartTime,
        modalEndTime,
        schedulerOptions
      );

      if (successfulCancelation) {
        closeModal();
        setPendingTimeSlot(null);
        getManagerViewAppointments();
        setEditSlot(null);
      }
    } finally {
      setCreatingOrEditingTimeSlots(false);
    }
  };

  function getHourString(hour, minute, format) {
    return moment({ hour: hour, minute: minute }).format(format);
  }

  function generateTimeOptions(isForStartTime = false) {
    let hours = [];
    for (let hour = 6; hour < 22; hour++) {
      if (hour == 21) {
        if (!isForStartTime) {
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        }
      } else if (hour == 6) {
        isForStartTime &&
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      } else {
        hours.push(
          <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
            {getHourString(hour, 0, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      }
    }
    return hours;
  }

  function handleNewStartTime(chosenTime) {
    const plus15 = moment(chosenTime, "HH:mm")
      .add(15, "minutes")
      .format("HH:mm");

    setNewStartTime(chosenTime);
    setNewEndTime(plus15);
    setIsValidDateRange(
      moment(`${newStartDate} ${chosenTime}`).isBefore(
        moment(`${newEndDate} ${plus15}`)
      )
    );
  }

  function handleClickOnDateInput() {
    document.getElementById("date-of-birth").showPicker();
  }

  const resetPatientsData = closeModal => {
    if (!data) {
      setSchedulerOptions({
        ...schedulerOptions,
        appointment_data: {
          appointment_patient_ids: [],
          appointment_topic_id: ""
        },
        description: "",
        recurring: false
      });
    } else {
      setSchedulerOptions({
        ...schedulerOptions,
        appointment_data: {
          appointment_patient_ids: [],
          appointment_topic_id: data.appointment_topic_id ?? ""
        },
        description: data.description ?? ""
      });
    }
    setTablePatients([]);
    closeModal();
  };

  const handleChangeAppointmentTopic = topicId => {
    setSchedulerOptions(schedulerOptions => ({
      ...schedulerOptions,
      appointment_data: {
        appointment_patient_ids:
          schedulerOptions.appointment_data.appointment_patient_ids,
        appointment_topic_id: topicId
      }
    }));

    const minutes = topics_duration.find(t => t.sTopicId === topicId)?.iMinutes;

    const timePlusMinutes = moment(newStartTime, "HH:mm")
      .add(minutes ?? 15, "minutes")
      .format("HH:mm");
    setNewEndTime(timePlusMinutes);
    setIsValidDateRange(
      moment(`${newStartDate} ${newStartTime}`).isBefore(
        moment(`${newEndDate} ${timePlusMinutes}`)
      )
    );
  };

  const getSelectedPatients = () => {
    return schedulerOptions.appointment_data.appointment_patient_ids;
  };

  const getAlreadyAssignedPatients = () => {
    let assignedPts = [];

    if (isManagerView && data) {
      assignedPts =
        schedulerOptions.appointment_data.appointment_patient_ids.map(x => {
          return { ...x, patient_id: x.user_id };
        });
    } else if (!isManagerView && data) {
      assignedPts = data.AssignedPatients.map(x => {
        return { ...x, patient_id: x.user_id };
      });
    }

    return assignedPts;
  };

  return (
    <div>
      {!isTimeOff && (
        <PureModal
          windowProps={{ closeable: false }}
          maxWidth="sm"
          fullWidth
          startOpen={true}
          onClose={() => setPendingTimeSlot(null)}
          renderTrigger={() => <span />}
          renderContent={({ closeModal }) => (
            <div className="create-time-slot-modal-container" ref={inputRef}>
              <div>
                <h3 className="modal-title">
                  {isExpired
                    ? "Expired Schedule"
                    : data
                      ? "Edit Schedule"
                      : "Create Schedule"}
                </h3>
              </div>
              <div>
                {isFetchingTopicsDuration ||
                  gettingUserAppointmentConfig ||
                  (!isExpired && isManagerView && !managerViewCoachConfig) ? (
                  <InlineWaiting />
                ) : (
                  <div className="appointment-topic-container">
                    <div className="third-icon-container">
                      <MdAssignment size="20px" />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label
                        htmlFor="appointment_topic"
                        className="input-label"
                      >
                        Appointment Topic
                      </label>

                      {data && !isManagerView ? (
                        <select
                          id="appointment_topic"
                          value={""}
                          disabled={true}
                        >
                          <option value="">{data.Topic}</option>
                        </select>
                      ) : (
                        <select
                          id="appointment_topic"
                          value={
                            schedulerOptions.appointment_data
                              .appointment_topic_id
                          }
                          onChange={({ target }) => {
                            handleChangeAppointmentTopic(target.value);
                          }}
                          disabled={isExpired || !canEdit}
                        >
                          {!data && (
                            <option value="">Choose appointment topic</option>
                          )}
                          {!isManagerView &&
                            userAppointmentConfig?.appointment_topics.map(
                              ({ value, text }) => (
                                <option value={value} key={value}>
                                  {text}
                                </option>
                              )
                            )}

                          {isManagerView &&
                            managerViewCoachConfig &&
                            managerViewCoachConfig?.appointment_topics.map(
                              ({ value, text }) => (
                                <option value={value} key={value}>
                                  {text}
                                </option>
                              )
                            )}
                        </select>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "5px" }}>
                  <MdSchedule size="20px" />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <DatePickerInput
                      placeholder={"MM/DD/YYYY"}
                      value={newStartDate}
                      handleDayChange={e => {
                        if (canEdit) {
                          setNewStartDate(moment(e).format("MM/DD/YYYY"));
                          setIsValidDateRange(
                            moment(
                              `${moment(e).format(
                                "MM/DD/YYYY"
                              )} ${newStartTime}`
                            ).isBefore(moment(`${newEndDate} ${newEndTime}`))
                          );
                        }
                      }}
                      fromDate={moment().toDate()}
                      inputProps={
                        isExpired || !canEdit
                          ? {
                            readOnly: true,
                            disabled: true,
                            style: { width: "90%" }
                          }
                          : { style: { width: "90%" } }
                      }
                    />
                    <select
                      style={{ width: "7rem" }}
                      value={newStartTime}
                      onChange={e => {
                        handleNewStartTime(e.target.value);
                      }}
                      disabled={isExpired || !canEdit}
                    >
                      {generateTimeOptions(true)}
                    </select>
                  </div>
                  <div style={{ display: "flex" }}>
                    <DatePickerInput
                      placeholder={"MM/DD/YYYY"}
                      value={newEndDate}
                      handleDayChange={e => {
                        setNewEndDate(moment(e).format("MM/DD/YYYY"));
                        setIsValidDateRange(
                          moment(`${newStartDate} ${newStartTime}`).isBefore(
                            moment(
                              `${moment(e).format("MM/DD/YYYY")} ${newEndTime}`
                            )
                          )
                        );
                      }}
                      fromDate={moment().toDate()}
                      inputProps={
                        isExpired || !canEdit
                          ? {
                            readOnly: true,
                            disabled: true,
                            style: { width: "90%" }
                          }
                          : { style: { width: "90%" } }
                      }
                    />
                    <select
                      style={{ width: "7rem" }}
                      value={newEndTime}
                      onChange={e => {
                        setNewEndTime(e.target.value);
                        setIsValidDateRange(
                          moment(`${newStartDate} ${newStartTime}`).isBefore(
                            moment(`${newEndDate} ${e.target.value}`)
                          )
                        );
                      }}
                      disabled={isExpired || !canEdit}
                    >
                      {generateTimeOptions()}
                    </select>
                  </div>
                </div>
              </div>
              {(data?.TopicId ||
                schedulerOptions.appointment_data.appointment_topic_id) && (
                  <>
                    <div style={{ display: "flex" }}>
                      <div className="second-icon-container">
                        <MdAssignment size="20px" />
                      </div>
                      <div className="chat-type-and-invites-container">
                        <div style={{ flex: "1", paddingRight: "20px" }}>
                          <div className="input-label-container">
                            <label
                              htmlFor="chat_type_select"
                              className="input-label"
                            >
                              Chat Type
                            </label>
                          </div>
                          {data?.ChatType ? (
                            <select
                              className="text-input-styles"
                              id="chat_type_select"
                              value={""}
                              disabled={true}
                            >
                              <option value="">{data.ChatType}</option>
                            </select>
                          ) : (
                            <select
                              className="text-input-styles"
                              id="chat_type_select"
                              value={schedulerOptions.chat_type}
                              onChange={({ target }) => {
                                setSchedulerOptions({
                                  ...schedulerOptions,
                                  chat_type: target.value,
                                  max_number_of_invitees:
                                    target.value == "Video_Chat"
                                      ? 10
                                      : target.value == "Undefined"
                                        ? 0
                                        : 1
                                });
                              }}
                              disabled={!canEdit}
                            >
                              <option value="Undefined">Choose Chat Type</option>
                              <option value="Text_Chat">Text Only Chat</option>
                              <option value="Video_Chat">Video Chat</option>
                            </select>
                          )}
                        </div>
                        <div style={{ flex: "1", paddingLeft: "20px" }}>
                          <div className="input-label-container">
                            <label
                              htmlFor="maximum_users"
                              className="input-label"
                            >
                              Max Invites
                            </label>
                          </div>
                          <input
                            className="text-input-styles"
                            style={
                              schedulerOptions.chat_type != "Product_Demo" ||
                                !canEdit
                                ? { color: "#d1c9c9" }
                                : undefined
                            }
                            type="number"
                            id="maximum_users"
                            value={schedulerOptions.max_number_of_invitees}
                            onChange={({ target }) => {
                              setSchedulerOptions({
                                ...schedulerOptions,
                                max_number_of_invitees: target.value
                              });
                              if (target.value > 10) {
                                setValidMaximumUsers(false);
                              } else {
                                setValidMaximumUsers(true);
                              }
                            }}
                            disabled={
                              schedulerOptions.chat_type != "Product_Demo" ||
                              !canEdit
                            }
                          />
                          {schedulerOptions.max_number_of_invitees > 10 ? (
                            <label
                              htmlFor="maximum_users"
                              style={{ color: "#ff0000" }}
                            >
                              Only 10 patients or less are allowed
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {data ? (
                      <div>
                        <p
                          style={{
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            color: "#4281a6"
                          }}
                        >{`Current Appointment/s: ${data.AssignedPatientsCount}`}</p>
                      </div>
                    ) : null}
                    {!isExpired && (canEdit || canAddPatients) ? (
                      <div className="invite-patients-button-container">
                        <button type="button" className="invite-patients-button">
                          <MdAddCircle size="20px" />
                          Invite Patient/s
                        </button>
                      </div>
                    ) : null}
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="third-icon-container">
                          <MdPeople size="20px" />
                        </div>
                        <div>
                          <div style={{ marginBottom: "10px" }}>
                            <p className="input-label">Assigned patients</p>
                            <div className="selected-patients-container">
                              {data &&
                                (!isManagerView || isExpired) &&
                                data.AssignedPatients?.map(
                                  ({ patient_name, user_id }) => {
                                    return (
                                      <div
                                        key={user_id}
                                        className="already-assigned-patients"
                                      >
                                        <p
                                          style={{
                                            margin: "0px",
                                            marginRight: "5px"
                                          }}
                                        >
                                          {patient_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              {schedulerOptions.appointment_data
                                .appointment_patient_ids.length > 0 &&
                                !isExpired &&
                                schedulerOptions.appointment_data.appointment_patient_ids.map(
                                  element => {
                                    return (
                                      <div
                                        key={element.user_id}
                                        className="invited-patients"
                                        onClick={() => {
                                          setSchedulerOptions({
                                            ...schedulerOptions,
                                            appointment_data: {
                                              ...schedulerOptions.appointment_data,
                                              appointment_patient_ids:
                                                schedulerOptions.appointment_data.appointment_patient_ids.filter(
                                                  patient =>
                                                    patient.user_id !==
                                                    element.user_id
                                                )
                                            }
                                          });
                                        }}
                                      >
                                        <p
                                          style={{
                                            margin: "0px",
                                            marginRight: "5px"
                                          }}
                                        >
                                          {element.patient_name}
                                        </p>
                                        <MdClose size="15px" />
                                      </div>
                                    );
                                  }
                                )}
                              {(data &&
                                data.AssignedPatients?.length == 0 &&
                                schedulerOptions.appointment_data
                                  .appointment_patient_ids.length == 0) ||
                                (!data &&
                                  schedulerOptions.appointment_data
                                    .appointment_patient_ids.length == 0) ? (
                                <p className="remove-margin-bottom">
                                  {isExpired ? "None" : "Patients"}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          {!isExpired && (canEdit || canAddPatients) ? (
                            <>
                              <div className="patient-search-inputs-container">
                                <div>
                                  <label htmlFor="name" className="input-label">
                                    Name or email
                                  </label>
                                  <input
                                    name="name"
                                    label="Name"
                                    type="text"
                                    id="name"
                                    style={{ width: "250px" }}
                                    onChange={e =>
                                      setToSearchPatientsWith({
                                        ...toSearchPatientsWith,
                                        name: e.target.value,
                                        email: e.target.value
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="maximum_users"
                                    className="input-label"
                                  >
                                    Account Number
                                  </label>
                                  <input
                                    name="account_number"
                                    label="Account Number"
                                    type="text"
                                    id="account_number"
                                    onChange={e =>
                                      setToSearchPatientsWith({
                                        ...toSearchPatientsWith,
                                        account_number: e.target.value
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="date-of-birth"
                                    className="input-label"
                                  >
                                    Date of birth
                                  </label>
                                  <div onMouseDown={handleClickOnDateInput}>
                                    <input
                                      type="date"
                                      id="date-of-birth"
                                      onChange={({ target }) =>
                                        setToSearchPatientsWith({
                                          ...toSearchPatientsWith,
                                          date_of_birth: target.value
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <button
                                  disabled={
                                    toSearchPatientsWith.name.length < 4 &&
                                    toSearchPatientsWith.account_number.length < 4
                                  }
                                  onClick={async () =>
                                    await fetchData(toSearchPatientsWith)
                                  }
                                  type="button"
                                  className="patient-search-button"
                                >
                                  <MdSearch size="20px" />
                                </button>
                              </div>
                              <PatientSearchResults
                                patients={tablePatients}
                                isFetching={isFetching}
                                selectedPatients={getSelectedPatients()}
                                setSelectedPatients={patient_id => {
                                  setSchedulerOptions({
                                    ...schedulerOptions,
                                    appointment_data: {
                                      appointment_patient_ids: patient_id,
                                      appointment_topic_id:
                                        schedulerOptions.appointment_data
                                          .appointment_topic_id
                                    }
                                  });
                                }}
                                feature="scheduleChat"
                                multiple={true}
                                maxInvitees={
                                  schedulerOptions.max_number_of_invitees
                                }
                                alreadyAssignedPatients={getAlreadyAssignedPatients()}
                                isManagerView={isManagerView}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div className="third-icon-container">
                          <MdDescription size="20px" />
                        </div>
                        <div style={{ flex: "1" }}>
                          <div className="input-label-container">
                            <label htmlFor="description" className="input-label">
                              Description
                            </label>
                            {!isExpired && canEdit && (
                              <label
                                htmlFor="description"
                                className="input-label"
                              >
                                Optional
                              </label>
                            )}
                          </div>
                          <textarea
                            id="description"
                            value={schedulerOptions.description}
                            maxLength="150"
                            onChange={({ target }) =>
                              setSchedulerOptions({
                                ...schedulerOptions,
                                description: target.value
                              })
                            }
                            disabled={isExpired || !canEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div className="action-buttons-container">
                <button
                  className="warning create-timeslot-button"
                  onClick={() => {
                    cancelButton(closeModal);
                  }}
                  disabled={creatingOrEditingTimeSlots}
                >
                  {!pendingTimeSlot ? "Close" : "Cancel"}
                </button>

                {data && isManagerView && !isExpired && canEdit && (
                  <MoveAppointment
                    onSubmit={() => {
                      refreshCalendars();
                      closeModal();
                    }}
                    appointmentId={data.AppointmentId}
                    customStyles={{ backgroundColor: "red", color: "white" }}
                    text={"Change Coach"}
                  />
                )}

                {data && isManagerView && !isExpired && (
                  <button
                    className="alert create-timeslot-button"
                    onClick={() => {
                      document
                        .getElementById("deleteTimeSlotConfirmationModal")
                        .click();
                    }}
                    disabled={creatingOrEditingTimeSlots}
                  >
                    Cancel Appointment
                  </button>
                )}

                {!isExpired && (canEdit || canAddPatients) && (
                  <>
                    {!data && (
                      <button
                        className="success create-timeslot-button"
                        onClick={() =>
                          handleSave(closeModal, false, null, true)
                        }
                        disabled={
                          creatingOrEditingTimeSlots ||
                          !validMaximumUsers ||
                          !isValidDateRange ||
                          aMomentIsSelectedInThePast ||
                          !schedulerOptions.appointment_data
                            .appointment_topic_id ||
                          schedulerOptions.appointment_data
                            .appointment_patient_ids.length == 0 ||
                          schedulerOptions.chat_type == "Undefined" ||
                          schedulerOptions.chat_type == "0"
                        }
                      >
                        Save & Confirm
                      </button>
                    )}

                    <button
                      className="create-timeslot-button"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        handleSave(closeModal, false);
                      }}
                      disabled={
                        creatingOrEditingTimeSlots ||
                        !validMaximumUsers ||
                        !isValidDateRange ||
                        aMomentIsSelectedInThePast ||
                        !schedulerOptions.appointment_data
                          .appointment_topic_id ||
                        schedulerOptions.appointment_data
                          .appointment_patient_ids.length == 0 ||
                        schedulerOptions.chat_type == "Undefined" ||
                        schedulerOptions.chat_type == "0" ||
                        schedulerOptions.description?.length > 150
                      }
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
              {!isValidDateRange ? (
                <p className="warning-text">
                  Start date must be set before end date
                </p>
              ) : null}
              {schedulerOptions.description?.length > 150 && (
                <p className="warning-text">
                  Description can not have more than 150 Characters
                </p>
              )}
              {aMomentIsSelectedInThePast && !isExpired && !isViewing ? (
                <p className="warning-text">
                  You can´t create a schedule in the past
                </p>
              ) : null}
              {!data &&
                !schedulerOptions.appointment_data.appointment_topic_id ? (
                <p className="warning-text">
                  The appointment topic is required
                </p>
              ) : null}
              {schedulerOptions.appointment_data.appointment_topic_id &&
                (schedulerOptions.chat_type == "Undefined" ||
                  schedulerOptions.chat_type == "0") ? (
                <p className="warning-text">The chat type is required</p>
              ) : null}
              {schedulerOptions.appointment_data.appointment_topic_id &&
                ((data &&
                  data.AssignedPatients?.length == 0 &&
                  schedulerOptions.appointment_data.appointment_patient_ids
                    .length == 0) ||
                  (!data &&
                    schedulerOptions.appointment_data.appointment_patient_ids
                      .length == 0)) ? (
                <p className="warning-text">Patients are required</p>
              ) : null}
              {creatingOrEditingTimeSlots ? (
                <div
                  className="modal-spinner-container"
                  style={{ height: "100%", width: "100%" }}
                >
                  <Spinner />
                </div>
              ) : null}
            </div>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="deleteTimeSlotConfirmationModal"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => {
                closeModal();
              }}
              onConfirm={() => cancelAppointmentButton(closeModal)}
            >
              <h3 className="edit-timeslot-modal-title">
                Are you sure you want to cancel the appointment?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="rescheduleTimeSlotConfirmationModal"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => {
                closeModal();
              }}
              onConfirm={() => rescheduleAppointmentButton(closeModal)}
            >
              <h3 className="edit-timeslot-modal-title">
                Are you sure you want to reschedule the appointment?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="createAppointmentWithoutPatients"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => {
                closeModal();
              }}
              onConfirm={() => handleSave(closeModal, true)}
            >
              <h3 className="edit-timeslot-modal-title">
                Are you sure you don’t want to invite Patients to the schedule?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="clearPatientsSelectionModal"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => {
                closeModal();
              }}
              onConfirm={() => resetPatientsData(closeModal)}
            >
              <h3 className="edit-timeslot-modal-title">
                Are you sure you want to abandon your changes?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="clearPatientsSelectionModal"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => {
                closeModal();
              }}
              onConfirm={() => resetPatientsData(closeModal)}
            >
              <h3 className="edit-timeslot-modal-title">
                Are you sure you want to abandon your changes?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
      {!isTimeOff && (
        <PureModal
          maxWidth="sm"
          fullWidth
          renderTrigger={({ openModal }) => (
            <button
              id="confirmAppointmentOutOfWorkingHoursModal"
              className="appointment-action-button cancel-appointment-button"
              style={{ display: "none" }}
              onClick={() => {
                openModal();
              }}
            ></button>
          )}
          renderContent={({ closeModal }) => (
            <ConfirmationDialog
              cancelButtonStyles="appointment-action-button cancel-action-button"
              confirmButtonStyles="appointment-action-button reschedule-button"
              continueMsg="Yes"
              cancelMsg="No"
              onCancel={() => closeModal()}
              onConfirm={() => {
                handleSave(closeModal, false, true);
                closeModal();
              }}
            >
              <h3 className="edit-timeslot-modal-title">
                You are about to create an appointment outside your working
                hours, do you want to Confirm?
              </h3>
            </ConfirmationDialog>
          )}
        />
      )}
    </div>
  );
};

CreateEditViewTimeSlotModal.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.object,
  managerView: PropTypes.bool,
  isViewing: PropTypes.bool
};
