import React, { useEffect, useState, useRef } from "react";
import { TimeSlotContext } from "../../availability-scheduler/use-availabilityScheduler";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { Spinner, withModal } from "components/ui";
import { useFetch } from "hooks";
import { errorMessage } from "actions/message";
import { get } from "utils/api";
import moment from "moment";
import PropTypes from "prop-types";
import DateSearch from "./date-search";
import "./search.scss";
import AppointmentsList from "./appointments-list";
import useApiQuery from "hooks/useQuery/api-query";
import { useDispatch, connect } from "react-redux";
import MoveAppointment from "../../available-coaches/Index";
import { fetchCompanyTable } from "actions/companiesTable";
import compose from "lodash/fp/compose";

const SearchAppointmentsModal = ({ onClose, fetchCompanyTable, companies }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState(false);

  const initialFilters = useRef({
    patient: "",
    company: "",
    branch: "",
    coach_status: "",
    coach: "",
    topic: ""
  });

  const initialDateFilters = useRef({
    date_from: moment().format("yyyy-MM-DD"),
    date_to: moment().add(30, "days").format("yyyy-MM-DD")
  });

  const [filters, setFilters] = useState(initialFilters.current);
  const [dateFilters, setDateFilters] = useState(initialDateFilters.current);

  const initialQuery = {
    sort: { direction: "asc", by: "StartDate" },
    filters: {
      ...initialFilters,
      Status: [],
      PatientName: filters.patient,
      CompanyName: filters.company,
      BranchName: filters.branch,
      Topic: filters.topic,
      ToDate: dateFilters.date_to,
      FromDate: dateFilters.date_from
    },
    pagination: { page: 1, per: 50 }
  };

  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("appointments/manager-view/paginated", initialQuery, "Result");

  const resetFilters = () => {
    setFilters(initialFilters.current);
    setDateFilters(initialDateFilters.current);
  };

  const {
    coaches,
    gettingCoaches,
    topicCategories,
    gettingTopicCategories,
    patients,
    gettingPatients
  } = React.useContext(TimeSlotContext);

  const handleChangeFilters = ({ name, value }) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const { fetchData: fetchBranches, isFetching: fetchingBranches } = useFetch({
    apiFn: () => get(`companies/${filters.company}/branches`),
    defaultValue: [],
    onError: error => {
      dispatch(
        errorMessage(
          `Failed to fetch company branches: ${getApiErrorMessageCustomPathEnd(
            error,
            "message"
          )}`
        )
      );
    }
  });

  const getBranches = async () => {
    const res = await fetchBranches();
    if (res) setBranches(res);
  };

  const doSearch = () => {
    const dateTo = moment(dateFilters.date_to, "yyyy-MM-DD");
    const dateFrom = moment(dateFilters.date_from, "yyyy-MM-DD");
    if (
      dateFrom &&
      dateTo &&
      dateTo.isBefore(dateFrom) &&
      !dateFrom.isSame(dateTo, "day")
    ) {
      dispatch(errorMessage("Start Date should be before End Date"));
      return;
    }

    queryDispatcher({
      type: "updateFilters",
      payload: {
        FromDate: dateFilters.date_from,
        ToDate: dateFilters.date_to,
        CoachStatus: filters.coach_status,
        CoachId: filters.coach,
        Patient: filters.patient,
        Company: filters.company,
        Branch: filters.branch,
        Topic: filters.topic
      }
    });
  };

  useEffect(() => {
    if (
      gettingCoaches ||
      gettingTopicCategories ||
      fetchingBranches ||
      gettingPatients
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    gettingCoaches,
    gettingTopicCategories,
    fetchingBranches,
    gettingPatients
  ]);

  useEffect(() => {
    if (filters.company) {
      getBranches();
    }
  }, [filters.company]);

  const [selectedAppointmentId, setSelectAppointmentId] = useState(null);

  const onMoveAppointmentSuccess = () => {
    resetFilters();
    setSelectAppointmentId(null);
    doSearch();
  };

  useEffect(() => {
    if (triggerSearch) {
      doSearch();
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  const getCompanies = async () => await fetchCompanyTable(null, null, 0);

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div className="search-appointments" style={{ padding: "1em" }}>
      <div className="search-controls">
        <h4 style={{ color: "#309bd9" }}>Search Appointments: </h4>
        <div className="search-fields-container">
          <div className="search-fields-column">
            <div className="search-field">
              <p style={{ padding: "0px", margin: "1px" }}>Patient</p>
              <input
                type="text"
                name="patient"
                value={filters.patient}
                onChange={({ target }) => handleChangeFilters(target)}
              />
            </div>
            <div className="search-field">
              <p style={{ padding: "0px", margin: "1px" }}>Company</p>
              <select
                name="company"
                value={filters.company}
                onChange={({ target }) => handleChangeFilters(target)}
              >
                <option value="">[All]</option>
                {companies.map(({ company_name, id }) => (
                  <option key={id} value={id}>
                    {company_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="search-field">
              <p style={{ padding: "0px", margin: "1px" }}>Branch</p>
              <select
                name="branch"
                value={filters.branch}
                onChange={({ target }) => handleChangeFilters(target)}
                disabled={!filters.company || branches?.length === 0}
              >
                <option value="">[All]</option>

                {branches?.map(({ branch_name, id }) => (
                  <option key={id} value={id}>
                    {branch_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-fields-column">
            <div className="search-fields-container">
              <div className="search-fields-column search-field">
                <p style={{ padding: "0px", margin: "1px" }}>Coach Status</p>
                <select
                  name="coach_status"
                  value={filters.coach_status}
                  onChange={({ target }) => handleChangeFilters(target)}
                >
                  <option value="">[All]</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="search-fields-column search-field">
                <p style={{ padding: "0px", margin: "1px" }}>Coach</p>
                <select
                  name="coach"
                  value={filters.coach}
                  onChange={({ target }) => handleChangeFilters(target)}
                >
                  <option value="">[All]</option>
                  {coaches?.map(coach => {
                    if (
                      (coach.Active && filters.coach_status === "active") ||
                      (!coach.Active && filters.coach_status === "inactive") ||
                      filters.coach_status === ""
                    ) {
                      return (
                        <option key={coach.CoachId} value={coach.CoachId}>
                          {coach.CoachName}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="search-fields-container">
              <div className="search-fields-column search-field">
                <p style={{ padding: "0px", margin: "1px" }}>Topic</p>
                <select
                  name="topic"
                  value={filters.topic}
                  onChange={({ target }) => handleChangeFilters(target)}
                >
                  <option value="">[All]</option>
                  {topicCategories.map(({ topic_id, topic_name }) => (
                    <option value={topic_id} key={topic_id}>
                      {topic_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="search-fields-column search-field"></div>
            </div>
            <DateSearch
              dateFrom={dateFilters.date_from}
              dateTo={dateFilters.date_to}
              setDateFilters={setDateFilters}
            ></DateSearch>
          </div>
        </div>
        <div className="search-header">
          <MoveAppointment
            onSubmit={onMoveAppointmentSuccess}
            appointmentId={selectedAppointmentId}
            text={"Move"}
          />
          <div
            className="action-buttons-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button
              className="create-timeslot-button"
              onClick={() => doSearch()}
              disabled={isFetching}
            >
              Search
            </button>
            <button
              className="alert remove-timeslot-button"
              onClick={() => {
                setTriggerSearch(true);
                resetFilters();
              }}
            >
              Reset
            </button>
            <button
              className="warning create-timeslot-button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
        {loading && (
          <div
            className="modal-spinner-container"
            style={{ height: "100%", width: "100%" }}
          >
            <Spinner size={"50px"} />
          </div>
        )}
      </div>
      <hr style={{ marginBottom: "10px" }} />
      <AppointmentsList
        data={data}
        isFetching={isFetching}
        fetchData={fetchData}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        setSelectedAppointment={setSelectAppointmentId}
        selectedAppointmentId={selectedAppointmentId}
      />
    </div>
  );
};

SearchAppointmentsModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fetchCompanyTable: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default compose(
  withModal,
  connect(
    state => ({
      companies: state.get("companiesTable")
    }),
    {
      fetchCompanyTable
    }
  )
)(SearchAppointmentsModal);
